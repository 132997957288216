// i18next-extract-mark-ns-start payments-orchestration

//Payment methods
import {Background} from 'components/Background';
import {Button} from 'components/Button';
import {Clients} from 'components/Clients';
import {Content} from 'components/Content';
import {HubspotForm} from 'components/HubspotForm';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {StyledImg} from 'components/StyledSx';
import {Helmet, useI18next} from 'gatsby-plugin-react-i18next';
import index2 from 'images/best_payment_gateway_rates.svg';
import moneiLogo from 'images/monei_logo.svg';

// Clients
import index6 from 'images/payment_provider.svg';

//Images
import paymentOrchestIndex_en from 'images/paymentOrchestIndex_en.png';
import paymentOrchestIndex_es from 'images/paymentOrchestIndex_es.png';
import paymentOrchestIndex_fr from 'images/paymentOrchestIndex_fr.png';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {Language} from '../../../../locales/types';
import {ScreenSizes} from '../../../../types/responsive';

const IndexBackground = styled.div`
  position: relative;

  &:before {
    display: block;
    content: '';
    position: absolute;
    transform: rotate(-58deg);
    left: 70%;
    top: 0;
    height: 31.25rem;
    width: 62.5rem;
    z-index: -1;
    background: #aab4e9;
    @media (max-width: ${ScreenSizes.md}) {
      display: none;
    }
  }
`;

const IndexContent = styled.div`
  max-width: 33rem;
  @media (max-width: ${ScreenSizes.lg}) {
    max-width: 32rem;
  }
  @media (max-width: ${ScreenSizes.md}) {
    max-width: 100%;
    padding-top: 0.625rem;
  }
`;

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;
    @media (max-width: ${ScreenSizes.sm}) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

export const IndexImage = styled.div`
  margin: 0px -70px 0 0;
  @media (max-width: ${ScreenSizes.lg}) {
    margin: 0;
  }

  @media (max-width: ${ScreenSizes.md}) {
    margin: 3.5rem 0 0 0;
    text-align: center;
    img {
      width: 65%;
    }
  }
  @media (max-width: ${ScreenSizes.xs}) {
    display: none;
  }
`;

const handleScrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

const paymentOrchestImgMap: Record<Language, string> = {
  de: paymentOrchestIndex_en,
  it: paymentOrchestIndex_en,
  pt: paymentOrchestIndex_en,
  en: paymentOrchestIndex_en,
  ca: paymentOrchestIndex_es,
  es: paymentOrchestIndex_es,
  fr: paymentOrchestIndex_fr
};

const OrchestrationSEM: React.FC = () => {
  const {language} = useI18next();
  const paymentOrchestImg: string = paymentOrchestImgMap[language as Language];

  return (
    <>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Orquestación de pagos</title>
        <meta
          name="description"
          content="Utiliza la orquestación de pagos para aumentar los ingresos, mejorar la experiencia del cliente y administrar todas tus opciones de pagos en una sola plataforma. Empezar ››"
        />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <IndexBackground>
        <Content>
          <Section
            centered
            sx={{marginTop: '1.875rem', flexDirection: {md: 'column'}, gap: '90px'}}>
            <IndexContent>
              <StyledImg src={moneiLogo} sx={{width: '300px', marginBottom: '54px'}} />
              <LargeSectionHeader underline tagName="h1">
                Aumenta tus ingresos con la orquestación de pagos
              </LargeSectionHeader>
              Aprueba más pagos y aumenta tu tasa de conversión con nuestro sistema de flujo de
              pagos optimizado. Obtén control y flexibilidad para dirigir las transacciones de los
              clientes a varios procesadores de pagos, todo desde un único panel de control.
              Cuéntanos sobre tu empresa y te guiaremos al lugar correcto.
            </IndexContent>
            <HubspotForm formId="1e6951bf-0cc8-4439-922b-f33a2ee988f5" minHeight={338} />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="Shopify payments orchestration"
              title="Shopify payments orchestration"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>Mejora la experiencia del cliente</SectionHeader>
              Con la orquestación de pagos, puedes establecer reglas de procesamiento de pagos en
              función de tus necesidades comerciales. Elige un procesador de pagos con tarjeta de
              crédito predeterminado y, si experimenta un tiempo de inactividad, enviaremos la
              transacción a otro. Dirigir los pagos al proveedor con mejor rendimiento reduce los
              fallos de pago, mejora la experiencia del cliente y genera más pagos aprobados (es
              decir, más ventas).
              <SectionActions>
                <Button variant="dark" style={{marginRight: 15}} onClick={handleScrollToTop}>
                  Contactar con ventas
                </Button>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                Gestiona todas tus opciones de pagos en una sola plataforma
              </SectionHeader>
              Los consumidores esperan que las empresas de e-commerce acepten una amplia gama de
              métodos de pago online. Esto incluye tarjetas de crédito, métodos de pago
              alternativos, como billeteras digitales, pagos a plazos y opciones de pago locales. En
              lugar de integrarte con muchos proveedores de servicios de pago, la orquestación de
              pagos agiliza el proceso para que puedas <b>ahorrar tiempo y dinero</b> al administrar
              todas tus opciones de pagos y los datos de transacciones en un solo panel.
              <SectionActions>
                <Button variant="dark" style={{marginRight: 15}} onClick={handleScrollToTop}>
                  Contactar con ventas
                </Button>
              </SectionActions>
            </div>
            <SectionImage
              src={index3}
              alt="Secure payments orchestration platform"
              title="Secure payments orchestration platform"
              width={400}
              height={428}
              mobileWidth={210}
            />
          </Section>
          <Section>
            <SectionImage
              src={paymentOrchestImg}
              alt="PCI payments orchestration platform"
              title="PCI payments orchestration platform"
              height={500}
              mobileWidth={280}
            />
            <div>
              <SectionHeader sx={{width: '100%'}}>Aprobar más pagos </SectionHeader>
              La implementación de funciones para mejorar las tasas de aprobación de transacciones
              debe ser una prioridad máxima. Enrutar los pagos, aceptar métodos de pago locales y
              establecer condiciones de los métodos de pago, como la relevancia para el cliente y la
              posibilidad de un pago exitoso, son beneficios de la orquestación de pagos que pueden
              ayudar a mejorar tus resultados finales.
              <SectionActions>
                <Button variant="dark" style={{marginRight: 15}} onClick={handleScrollToTop}>
                  Contactar con ventas
                </Button>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>¿Estás listo para optimizar tus flujos de pago? </SectionHeader>
              Conecta MONEI con las principales plataformas de e-commerce o intégralo con tu sitio
              web personalizado utilizando la API de MONEI
              <SectionActions>
                <Button variant="dark" style={{marginRight: 15}} onClick={handleScrollToTop}>
                  Contactar con ventas
                </Button>
              </SectionActions>
            </div>
            <SectionImage
              src={index6}
              alt="payments orchestration platform for Developers"
              title="payments orchestration platform for Developers"
              width={392}
              height={358}
            />
          </Section>
        </Content>
      </Background>
      <Content>
        <Section textAlign="center">
          <div>
            <SectionHeader underline tagName="h3">
              Utilizado con orgullo en más de 5.000 tiendas{' '}
            </SectionHeader>
            <Clients />
          </div>
        </Section>
      </Content>
      <Content>
        <Section textAlign="center">
          <div>
            <SectionHeader underline>¿Aún sigues aquí?</SectionHeader>
            <p>Prueba MONEI ahora</p>
            <SectionActions align="center" style={{marginBottom: 100}}>
              <Button variant="light" style={{marginRight: 15}} onClick={handleScrollToTop}>
                Contactar con ventas
              </Button>
            </SectionActions>
          </div>
        </Section>
      </Content>
    </>
  );
};

export default OrchestrationSEM;
